import { useState } from "react";
import MainBanner from "../../Components/main-banner/MainBanner";
import OurStory from "../our-story/OurStory";
import EventInfoCard from "../event-info/EventInfo"
import WeddingCountdown from "../../Components/counter/counter";
import './home.scss';

export default function Home(){
  // eslint-disable-next-line
  const [eventInfo, setEventInfo] = useState({
    title: "When & Where",
    date: "June 15th, 2024",
    time: "5:00PM",
    location: "Los Angeles, CA",
    description: "Lorem ipsum dolor sit amet. Ut aperiam quia ut quibusdam molestias ut dolores molestiae ut doloribus consequatur! Et quibusdam internos quo laudantium minus vel velit iure vel commodi vitae. Qui omnis reprehenderit eum consequatur sunt in alias alias aut nihil nihil."
  });
  
  return(
    <>
      <MainBanner/>
      <WeddingCountdown targetYear={2024} targetMonth={6} targetDay={15} targetHourInMilitaryTime={18}></WeddingCountdown>
      <OurStory/>
      <EventInfoCard 
        title={eventInfo.title} 
        date={eventInfo.date} 
        time={eventInfo.time} 
        location={eventInfo.location} 
        description={eventInfo.description}
      />
    </>
  )
}