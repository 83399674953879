import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './Components/navbar/Navbar';
import { useState } from 'react';
import Home from './Pages/home/Home';

import './App.css';

function App() {
  // eslint-disable-next-line
  const [topNavProps, setTopNavProps] = useState({
    container: {
      backgroundColor: "#fff",
      height: '55px',
    },
    navlogo: {
      text: "J&S",
      color: "#7c7c7c",
      fontSize: "1.6rem"
    },
    menuIcon: {
      color: '#7c7c7c'
    }
  });

  const navList = [
    { 
      name: 'Our Story',
      path: '#our-story',
    },
    { 
      name: 'About Us',
      path: '#aboutus',
    },
    { 
      name: 'Events',
      path: '#events',
    },
    { 
      name: 'Gallery',
      path: '#gallery',
    }
  ]

  return (
    <div className="App">
      <>
      <BrowserRouter>
        <Navbar topNavProps={topNavProps} navList={navList}/>
          <Routes>
            <Route path='/' element={<Home />}></Route>
          </Routes>
      </BrowserRouter>
      </>
    </div>
  );
}

export default App;
