import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import "./counter.scss"

function WeddingCountdown({targetYear, targetMonth, targetDay, targetHourInMilitaryTime }: any) {
    const [months, setMonths] = useState(0);
    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    useEffect(() => {
        const intervalId = setInterval(() => {
          const currentDate = new Date();
          const year = targetYear;
          const month = targetMonth - 1;
          const day = targetDay;
          const targetHour = targetHourInMilitaryTime
          const targetDate = new Date(year, month, day, targetHour);

          const timeDifference = targetDate.getTime() - currentDate.getTime();
      
          // Calculate months
          const totalMonths = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30.4375)); // Months
          const remainingMonths = totalMonths - Math.floor(totalMonths / 12) * 12;
      
          // Calculate days, accounting for months
          const daysInMonths = remainingMonths * 30.4375; // Approximate days in remaining months
          const remainingTime = timeDifference - (daysInMonths * 1000 * 60 * 60 * 24);
          const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
            
          // Calculate remaining hours, minutes, and seconds
          const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

            setMonths(totalMonths)
            setDays(days);
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);

        }, 1000);
      
        return () => clearInterval(intervalId);
      });
  
    return (
      <div className="wedding-counter-container">
        <Typography variant='h3'>The Countdown</Typography>
        <Typography variant='body1' align='center'>Lorem ipsum dolor sit amet. Est rerum velit eum debitis repellat aut omnis aliquid. Est enim beatae sed alias repudiandae non vero voluptas. Ab reiciendis inventore et incidunt impedit ex minus voluptatem quo fugit labore et reiciendis iste eos voluptas animi. Ea deleniti excepturi et autem animi ut voluptatem neque qui voluptatibus nulla et vitae optio rem cumque natus.</Typography>
        <div className='counter'>
          <div className="wedding-numbers">
              <h2>{months}</h2>
              <p>Months</p>
          </div>
          <div className="wedding-numbers">
              <h2>{days}</h2>
              <p>Days</p>
          </div>
          <div className="wedding-numbers">
              <h2>{hours}</h2>
              <p>Hours</p>
          </div>
          <div className="wedding-numbers">
              <h2>{minutes}</h2>
              <p>Minutes</p>
          </div>
          <div className="wedding-numbers">
              <h2>{seconds}</h2>
              <p>Seconds</p>
          </div>
        </div>
      </div>
    );
  }
  
  export default WeddingCountdown;
