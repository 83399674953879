import { Drawer, List, ListItemText, ListItemButton, IconButton, Typography, Container } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import "./navbar.scss";


export default function Navbar({topNavProps, navList}: any) {
  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  function scrollToSection(targetLink: string){
    let offsetPosition;
    
    // close the sidenav
    toggleDrawer();

    // modify and get target div
    // const modifiedTargetLink = targetLink.substring(1);
    let targetSection = document.getElementById(targetLink);
    if(targetSection){
      offsetPosition = targetSection?.offsetTop - 55;
    }
    
    // check and scroll to target link
    if (targetSection) {
      window.scrollTo({
        top: offsetPosition ? offsetPosition : targetSection.offsetTop,
        behavior: 'smooth',
      });
    }
  }

  return (
    <>
      <Container component={'div'} maxWidth={'xl'} disableGutters 
        sx={{ 
        display: 'flex',
        backgroundColor: topNavProps.container.backgroundColor,
        height: topNavProps.container.height
        }}
        className="top-nav-container"
      >
        <Link to="/" className="removeUnderline">
          <Typography
            sx={{
              color: topNavProps.navlogo.color,
              fontSize: topNavProps.navlogo.fontSize,
              textDecoration: 'none',
              fontFamily: "'Merriweather', serif;"
            }}
          >
            {topNavProps.navlogo.text}
          </Typography>
        </Link>
        <IconButton onClick={toggleDrawer}>
        <MenuIcon sx={{color: topNavProps.menuIcon.color}} />
        </IconButton>
      </Container>

      <Drawer anchor="left" open={open} onClose={toggleDrawer}>
        {/* Add your drawer content here */}
        <div style={{ width: '250px' }}>
          <List>
            { 
              navList.map((item: any, index: number) => (
                <Link to={item.path} className="top-nav-link" key={index} onClick={() => scrollToSection(item.path)}>
                  <ListItemButton>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </Link>                
              ))
            }
          </List>
        </div>
      </Drawer>
    </>
  );
}
