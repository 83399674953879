import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import './event-info.scss'
import { Grid } from '@mui/material';

const WeddingEventInfo = ({ title, date, time, location, description }: any) => {
  return (
    <Box component={'div'} className='event-info-container' id='#events'>
      <Typography variant="h3" component="h2" gutterBottom>{title}</Typography>
      <Typography variant="body1" sx={{mb: 4, textAlign: 'center'}}>{description}</Typography>

      <Grid container className='event-cal-loc-container'>
        <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center'}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
            <EventIcon sx={{fontSize: '50px'}} />
            <Typography variant="h6">{date}</Typography>
            <Typography variant="h6">{time}</Typography>
          </Box>
        </Grid>
        <Grid xs={6} sx={{ display: 'flex', justifyContent: 'center'}}>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
            <LocationOnIcon sx={{fontSize: '50px'}} />
            <Typography variant="h6">{location}</Typography>
          </Box>
        </Grid>
        <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center', margin: '1.5rem 1rem'}}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d129696.42806069354!2d-118.32655187226719!3d34.05906197470744!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2c75ddc27da13%3A0xe22fdf6f254608f4!2sLos%20Angeles%2C%20CA!5e0!3m2!1sen!2sus!4v1709104297894!5m2!1sen!2sus" 
              width="800" 
              height="325" 
              allowFullScreen={false}
              loading="lazy" 
              style={{border: '0', borderRadius: '20px'}}
              title='Map'
            ></iframe>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WeddingEventInfo;