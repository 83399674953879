import { useState } from 'react';
import { Typography, Box } from '@mui/material'
import './main-banner.scss'

export default function MainBanner(){
  // eslint-disable-next-line
  const [mainBannerProps, setmainBannerProps] = useState({
    title: {
      text: "Jehan & Stephanie", 
      color: "#fff", 
      fontSize: "2.2rem",
      fontFamily: "'Poppins', serif",
      fontWeight: '700'
    },
    subtitle: "",
    date: {
      text: "June 15th, 2024",
      color: "#fff", 
      fontSize: "1.8rem",
      fontFamily: "'Poppins', serif"
    },
    location: "Los Angeles, CA",
  });

  // const { scrollYProgress } = useScroll();
  // const opacity = useTransform(scrollYProgress, [0, 0.4], [1, 0]);
  // const scale = useTransform(scrollYProgress, [0, 0.4], [1, 0]);

  return(
    // <motion.div style={{opacity}} className='main-banner'>
    <div className="main-banner">
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center'}}>
        {
          mainBannerProps.title ? 
            <Typography 
              component='h2' 
              className='title' 
              sx={{
                color: mainBannerProps.title.color, 
                fontFamily: mainBannerProps.title.fontFamily,
                fontSize: mainBannerProps.title.fontSize
              }}
            >
              {mainBannerProps.title.text}
            </Typography> : null
        }
        {
          mainBannerProps.date ? 
            <Typography 
              component='h2' 
              className='title' 
              sx={{
                color: mainBannerProps.date.color, 
                fontFamily: mainBannerProps.date.fontFamily,
                fontSize: mainBannerProps.date.fontSize
              }}
            >
              {mainBannerProps.date.text}
            </Typography> : null
        }
      </Box>

    </div>
    // </motion.div>
  )
}