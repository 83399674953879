import { useState } from "react";
import Grid from '@mui/material/Unstable_Grid2'; 

import bridePortraitImage from './../../assets/images/engagement-photo-3.jpeg';
import groomPortraitImage from './../../assets/images/engagement-photo-4.jpeg';
import './our-story.scss';

export default function OurStory(){
  // eslint-disable-next-line
  const [ourStoryProps, setOurStoryProps] = useState({
    title: "Our Story",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tortor aliquam nulla facilisi cras. Amet aliquam id diam maecenas ultricies mi. Proin sed libero enim sed faucibus turpis. Tempor orci dapibus ultrices in iaculis nunc. Nibh nisl condimentum id venenatis a condimentum vitae sapien pellentesque. Ut consequat semper viverra nam libero. Mattis enim ut tellus elementum sagittis vitae et. Maecenas sed enim ut sem. Adipiscing commodo elit at imperdiet dui. Nibh venenatis cras sed felis."
  });
  // eslint-disable-next-line
  const [brideStoryProps, setBrideStoryProps] = useState({
    name: "Stephanie",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tortor aliquam nulla facilisi cras. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });
  // eslint-disable-next-line
  const [groomStoryProps, setGroomStoryProps] = useState({
    name: "Jehan",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tortor aliquam nulla facilisi cras. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  });
  // const targetRef = useRef(null)
  // const { scrollYProgress } = useScroll({
  //   target: targetRef,
  //   offset: ["start end", "end end"]
  // });

  // const scale = useTransform(scrollYProgress, [0, 0.75], [2.5, 1]);
  // const opacity = useTransform(scrollYProgress, [0, 0.6], [0, 1]);
  
  return (
    <>
      {/* <motion.div ref={targetRef} style={{scale, opacity}} className="our-story-container"> */}
      <div className="our-story-container" id="#our-story">
        <h2 className="title">{ourStoryProps.title}</h2>
        <p className="text">{ourStoryProps.text}</p>
      </div>
      {/* </motion.div> */}
      

      <div className="brgr-container" id="#aboutus">
        {/* Bride */}
        <Grid container display={'flex'} padding={"1rem"} alignItems={'center'}>
          <Grid xs={12} md={6}>
            <img src={bridePortraitImage} className="portrait-image" alt="bride portrait"></img>
          </Grid>
          <Grid xs={12} md={6}>
            <div className="title-and-text-container">
              <h2 className="title">{brideStoryProps.name}</h2>
              <p className="text">{brideStoryProps.text}</p>
            </div>
          </Grid>
        </Grid>

        {/* Groom */}
        <Grid container display={'flex'} padding={"1rem"} flexDirection={"row-reverse"} alignItems={'center'}>
          <Grid xs={12} md={6}>
            <img src={groomPortraitImage} className="portrait-image" alt="groom portrait"></img>
          </Grid>
          <Grid xs={12} md={6}>
            <div className="title-and-text-container">
              <h2 className="title">{groomStoryProps.name}</h2>
              <p className="text">{groomStoryProps.text}</p>
            </div>
          </Grid>
        </Grid>
      </div>

    </>
  );
}